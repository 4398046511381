import { graphql } from "gatsby"
import React from "react"

import Paragraphs from "@atoms/Paragraphs/Paragraphs"
import VideoStoryOrganism from "@organisms/VideoStory/VideoStory"

const VideoStory = ({
  subtitle,
  title,
  text,
  label,
  poster,
  video,
  youtubeUrl,
}) => {
  const { sourceUrl: posterUrl = "" } = poster ? poster : {}
  const { mediaItemUrl: videoUrl = "" } = video ? video : {}

  return (
    <>
      <VideoStoryOrganism
        meta={subtitle}
        title={title}
        text={<Paragraphs content={text} />}
        cta={label}
        poster={posterUrl}
        mp4={videoUrl}
        youTubeUrl={youtubeUrl}
      />
    </>
  )
}

export const query = graphql`
  fragment VideoStory on WpVideoStory {
    __typename
    subtitle
    title
    text
    label
    poster {
      sourceUrl
    }
    video {
      mediaItemUrl
    }
    youtubeUrl
  }
`

export default VideoStory
